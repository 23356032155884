<script setup lang="ts">

import {watch} from "vue";
import {ElMessage} from "element-plus";
import {checkUserStatus} from "@/utils";
import {useRouter} from "vue-router";

const router = useRouter()

watch(router.currentRoute, () => {
  checkUserStatus((error) => {
    ElMessage.error(error.message || '登录状态获取失败，请刷新后重试')
  })
}, {
  immediate: true,
  deep: true
})

</script>

<template>
  <router-view />
</template>

<style scoped>
</style>

// 首页
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/welcome',
        name: 'Welcome',
        meta: {
            title: '欢迎首页',
            auth: true
        },
        component: () => import('../views/welcome/index.vue')
    }
]

export default routes


import { defineStore } from "pinia";

interface AccountStore {
    nonce?: string
    targetUrl?: string
    userInfo: {
        userAccount: string
    }
}

export const useAccountStore = defineStore('token', {
    state: (): AccountStore => {
        return {
            nonce: '', // 凭证，如有
            targetUrl: '', // 跳转地址，如有
            userInfo: {
                userAccount: '', // 登录账号
            } // 用户信息
        }
    },
    actions: {
        getNonce() {
            return this.nonce
        },
        getTargetUrl() {
            return this.targetUrl
        },
        getUserInfo() {
            return this.userInfo
        },
        setAccount(nonce?: string, targetUrl?: string) {
            this.nonce = nonce;
            this.targetUrl = targetUrl;
        },
        setUserInfo(userAccount: string) {
            this.userInfo = {
                userAccount: String(userAccount),
            }
        },
        removeAccount() {
            this.nonce = ''
            this.targetUrl = ''
        },
        removeUserInfo() {
            this.userInfo = {
                userAccount: '',
            }
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'gimc-sso-account-nonce',
                storage: sessionStorage,
                paths: ['nonce']
            },
            {
                key: 'gimc-sso-account-targetUrl',
                storage: sessionStorage,
                paths: ['targetUrl']
            },
            {
                key: 'gimc-sso-account-userInfo',
                storage: sessionStorage,
                paths: ['userInfo']
            }
        ]
    }
})

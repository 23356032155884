import {useBaseApi} from "@/hooks/useBaseApi.ts";
import {getUserInfo, getUserLogin} from "@/apis/login.ts";
import {useAccountStore} from "@/store/modules/account.ts";
import router from "@/router";

// 校验登录
export const checkUserStatus = async (errorCallback?: (err) => void) => {
    const accountStore = useAccountStore()
    const { res, error, doFetch } = useBaseApi(getUserLogin, null, () => {})
    const userInfo = useBaseApi(getUserInfo, null, () => {})
    await doFetch()
    await userInfo.doFetch()

    if (userInfo.res.value) {
        accountStore.setUserInfo(userInfo.res.value)
    }

    if (error.value) {
        errorCallback && errorCallback(error.value)
        return
    }
    if (!res.value) {
        accountStore.setAccount('', accountStore.getTargetUrl())
        if (location.pathname !== '/login') {
            router.push({name: 'Home'})
        }
    } else if (res.value && (location.pathname === '/login' || location.pathname === '/')){
        router.replace({name: 'Welcome'})
    }
}

// 异常状态码
export enum ErrorCode {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    REQUEST_TIMEOUT = 408,
    INTERNAL_SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIMEOUT = 504,
    HTTP_VERSION_NOT_SUPPORTED = 505
}

// 异常文本
const ErrorCodeTextArray = [
    '请求错误',
    '未授权，请重新登录',
    '拒绝访问',
    '请求出错',
    '请求超时',
    '服务器错误',
    '服务未实现',
    '网络错误',
    '服务不可用',
    '网络超时',
    'HTTP版本不受支持'
]

type ErrorCodeTextType = {
    [K in keyof ErrorCode]: string
}

export const ErrorCodeText: ErrorCodeTextType = (() => {
    const tempErrorObj: ErrorCodeTextType = {} as ErrorCodeTextType
    Object.keys(ErrorCode).forEach((value, key) => {
        if (!isNaN(+value)) {
            tempErrorObj[value as keyof ErrorCode] = `${ErrorCodeTextArray[key]}(${value})`
        }
    })
    return tempErrorObj
})()

import {ref, unref, isRef, watchEffect} from "vue";

// TODO: 写一个好的重载
// TODO: immediate为true的时候变成了一个异步不是同步
// 基础版没有翻页的api hooks
// export function useBaseApi<Params, Resource>(apiFn: (arg?: Params) => Promise<Resource>);
// export function useBaseApi<Params, Resource>(apiFn: (arg?: Params) => Promise<Resource>, immediate: boolean);
// export function useBaseApi<Params, Resource>(apiFn: (arg?: Params) => Promise<Resource>, params: Params);
// export function useBaseApi<Params>(apiFn: (arg: Params) => Promise<void>, params: Params, immediate?: boolean);
// export function useBaseApi<Params, Resource>(apiFn: (arg?: Params) => Promise<Resource>, cb: () => void);
// export function useBaseApi<Params>(apiFn: (arg: Params) => Promise<void>, cb: () => void, immediate?: boolean);
export function useBaseApi<Params, Resource>(apiFn: (arg?: Params) => Promise<Resource>, params: Params, cb: () => void, immediate?: boolean) {
    const loading = ref<boolean>(false)
    const error = ref<unknown>(null)
    const res = ref()

    async function doFetch() {
        // 重置数据
        error.value = null
        res.value = null
        try {
            loading.value = true
            if (params) {
                const paramsVal = unref(params)
                res.value = await apiFn(paramsVal)
            } else {
                res.value = await apiFn()
            }
            loading.value = false
            cb && cb()
        } catch (e) {
            loading.value = false
            error.value = await e
            cb && cb()
        }
    }

    if (immediate) {
        // 参数是ref格式数据时，改变数据就会调用接口
        if (isRef(params)) {
            //此处可以加防抖，可参考表格hook封装方式
            watchEffect(doFetch)
        } else {
            doFetch()
        }
    }

    return {
        error,
        loading,
        res,
        doFetch
    }
}

// 首页
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/403',
        name: '403',
        meta: {
            title: '403',
            auth: true
        },
        component: () => import('@/views/errors/403.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
            auth: true
        },
        component: () => import('@/views/errors/404.vue')
    }
]

export default routes


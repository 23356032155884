import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'SignIn',
        meta: {
            title: '省广后台联合登录页面'
        },
        component: () => import('../views/account/login.vue')
    },
    {
        path: '/checkStatus',
        name: 'Checking',
        meta: {
            title: '统一认证状态获取页面'
        },
        component: () => import('../views/account/checkStatus.vue')
    },
    {
        path: '/consent',
        name: 'Auth',
        meta: {
            title: '省广联合登录授权'
        },
        component: () => import('../views/account/auth.vue')
    }
]

export default routes

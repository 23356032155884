import axios, { AxiosInstance } from "axios";

import type { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import {AxiosError} from "axios/index";

// 定义传入的拦截器接口，并且都是可以可选的。
interface IRequestInterceptors<T = AxiosResponse> {
    // 请求成功时的拦截器
    requestSuccessInterceptor?: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
    // 请求失败时的拦截器
    requestErrorInterceptor?: (err: AxiosError) => any;
    // 响应成功时的拦截器
    responseSuccessInterceptor?: (res: T) => T;
    // 响应失败时的拦截器
    responseErrorInterceptor?: (err: any) => any;
}
interface IBody<T> {
  success: boolean;
  message: string;
  code: number;
  body: T;
}

export interface IRequestConfig<T = AxiosResponse> extends AxiosRequestConfig {
    // 每个request实例可以不传入拦截器
    interceptors?: IRequestInterceptors<T>;
}

class Request {
    public instance: AxiosInstance;

    constructor(config: IRequestConfig) {
        this.instance = axios.create(config);
        // 先创建实例请求拦截器
        this.instance.interceptors.request.use(
            config.interceptors?.requestSuccessInterceptor,
            config.interceptors?.requestErrorInterceptor
        );
        // 先创建实例请求拦截器
        this.instance.interceptors.response.use(
            config.interceptors?.responseSuccessInterceptor,
            config.interceptors?.responseErrorInterceptor
        );
    }

    request<T>(config: IRequestConfig<IBody<T>>): Promise<T> {
        return new Promise((resolve, reject) => {
            if (config.interceptors?.requestSuccessInterceptor) {
                config = config.interceptors.requestSuccessInterceptor(config as any)
            }
            this.instance
                .request<any, IBody<T>>(config)
                .then((res) => {
                    if (config.interceptors?.responseSuccessInterceptor) {
                        res = config.interceptors?.responseSuccessInterceptor(res)
                    }
                    resolve(res.body)
                }).catch((err) => {
                    reject(err)
            })
        })
    }

    get<T>(config: IRequestConfig<IBody<T>>): Promise<T> {
        return this.request<T>({ ...config, method: 'GET' });
    }

    post<T>(config: IRequestConfig<IBody<T>>): Promise<T> {
        return this.request<T>({ ...config, method: 'POST' });
    }

    delete<T>(config: IRequestConfig<IBody<T>>): Promise<T> {
        return this.request<T>({ ...config, method: 'DELETE' });
    }

    patch<T>(config: IRequestConfig<IBody<T>>): Promise<T> {
        return this.request<T>({ ...config, method: 'PATCH' });
    }
}

export default Request

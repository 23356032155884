import { ErrorCode, ErrorCodeText } from '@/constants/ErrorCode';
import {AxiosError, AxiosResponse} from "axios";
import Request from "./Request";
import { stringify } from 'qs';
import { AxiosRequestConfig } from "axios";
import IError from "./IError";
import { ElMessage } from "element-plus";

const request = new Request({
    baseURL: import.meta.env.VITE_API_URL || '/',
    timeout: 60000, // 请求超时时间
    withCredentials: true, // 跨域请求是否携带cookie
    // crossDomain: true,
    headers: {
        'Cache-Control': 'no-cache',
        'If-Modified-Since': '0'
    }
})

request.instance.interceptors.request.use(
    (config) => {
        // const userStore = useAccountStore()
        // if (userStore.getNonce() && config.url && !config.url.includes('login')) {
        //     config.headers.Authorization = `bearer ${userStore.nonce}` // 让每个请求携带自定义token 请根据实际情况自行修改
        // }
        return config
    },
    (error: AxiosError) => {
        return new IError({
            message: error.message || 'Network Error',
            code: error.code || ErrorCode.NOT_FOUND
        })
    }
)

request.instance.interceptors.response.use(
    (response: AxiosResponse) => {
        const res = response.data
        if (
            res.code === ErrorCode.UNAUTHORIZED ||
            res.code === ErrorCode.FORBIDDEN &&
            !location.pathname.includes('/login')
        ) {
            ElMessage({ message: res.code ? ErrorCodeText[res.code as keyof ErrorCode] : res.message || '请求错误', type: 'error' })
            // if (location.pathname !== '/login') {
            //     window.location.reload()
            // }
            return Promise.reject(res)
        }
        else if(res?.code !== 200) {
            // ElMessage({ message: res.message || '请求异常', type: 'error' })
            return Promise.reject(res)
        }

        return Promise.resolve(res)
    },
    (error) => {
    //   new IError({
    //     message: error.message,
    //     code: error.code
    // })
    //     ElMessage({ message: error.message, type: 'error' })
    // response 错误的时候reject不往下走
      return Promise.reject(error)

    }
)

export const get = <T>(url: string, params?: any, config = {}): Promise<T> =>
    request.get<T>({
        url,
        params,
        ...config
    })

export const post = <T>(
    url: string,
    params?: any,
    config: AxiosRequestConfig = {}
): Promise<T> => {
    if (
        config &&
        config.headers &&
        config.headers['Content-Type'] &&
        config.headers['Content-Type'].includes('x-www-form-urlencoded') &&
        params
    ) {
        params = stringify(params);
    }
    return request.post<T>({
        url,
        data: params,
        ...config
    });
};

import { get, post } from '@/utils/http/IRequest'
import {CaptchaRes, LoginInfo,ResetReq,RestPswReq} from "@/interface/account";

// 登陆
export const userLogin = (data: LoginInfo) => post<string>('/auth/api/login', data, {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})

// 退出登陆
export const userLogout = () => get('/auth/api/logout')

// 获取验证码
export const getCaptcha = () => get<CaptchaRes>('/auth/api/getCaptcha')
// 获取短信验证码
export const getSMS = (username:string)=>get('/auth/api/captcha/sms',{phone:username})

// 获取登录状态
export const getUserLogin = () => get<boolean>('/auth/api/loggedIn')

// 获取用户信息
export const getUserInfo = () => get<string>('/auth/api/username')

// 获取重置密码的验证码
export const getResetPsw = (params:ResetReq)=>get('/auth/api/captcha/reset',{...params})

// 重置密码
export const pswReset = (data: RestPswReq) => post('/auth/api/password/reset', data, {})

export type ErrorType = {
    code: string | number;
    message: string;
}

class IError extends Error {
    code: string | number;
    message: string;

    constructor({code, message}: ErrorType) {
        super(message);
        this.code = code;
        this.message = message;
    }
}

export default IError

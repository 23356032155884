import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import accountRouters from './account';
import welcomeRouters from './welcome';
import errorsRouters from './errors';
import {useAccountStore} from "@/store/modules/account.ts";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        redirect: '/login'
    },
    ...accountRouters,
    ...welcomeRouters,
    ...errorsRouters,
    {
        // 404页面
        path: '/:pathMatch(.*)*',
        component: () => import('../views/errors/404.vue')
    }
]

const router = createRouter({
    history: createWebHistory(''),
    routes
})

// 路由拦截
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const userStore= useAccountStore()
    console.log('to', to)
    if (to.path === '/login') {
        if (!userStore.getNonce() && to.query && to.query.nonce) {
            userStore.setAccount(to.query.nonce as string || '', to.query.target as string || userStore.getTargetUrl())
        }
        // 有nonce的话不用跳登录页
        else if (userStore.getNonce()) {
            if (from.path === '/' || from.path === '') {
                next('/welcome')
            } else {
                next(false)
            }
        }
    }
    next()
})

export default router

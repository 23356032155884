import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import pinia from "./store";
import ElementPlus from 'element-plus';
import './styles/index.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import 'element-plus/dist/index.css'

const app = createApp(App)

app
    .use(router)
    .use(pinia)
    .use(ElementPlus, {
        locale: zhCn
    })
    .mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
